import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import CustomDataModal from "../components/UI/CustomDataModal";

export default function ChangePassword({ internal }) {
  const ui = useSelector((state) => state.account.ui);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const [transactionPassword, setTransactionPassword] = React.useState("");
  const toast = useToast();
  const dispatch = useDispatch();
  const changePassword = async () => {
    if (password === "" || confirmPassword === "" || oldPassword === "") {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Password and confirm password does not match",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (password === oldPassword) {
      toast({
        title: "Error",
        description: "New password and old password cannot be same",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (password.length < 5) {
      toast({
        title: "Error",
        description: "Password must contain at least 5 characters",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    try {
      var data = await changePasswordAPI(
        {
          newPassword: Encrypt(password),
          password: Encrypt(oldPassword),
          resetRequired: false,
          uid: userData.id,
        },
        userData.token
      );
      console.log(data);
      if (data?.code === 200) {
        toast({
          title: "Success",
          description: "Password changed successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        window.location.href = "/tp";
      } else {
        toast({
          title: "Error",
          description: "Wrong Password",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }
  useEffect(() => {
    toast({
      title: "Info",
      description: "Please change your password",
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }, []);

  return (
    <Box p="5">
      <Modal
        blockScrollOnMount={false}
        scrollBehavior="inside"
        isCentered
        size={"4xl"}
        isOpen={isPopUpOpen}
        onClose={() => {
          setIsPopUpOpen(false);
          dispatch({
            type: "accountData/closePopUp",
            payload: {
              modalOpen: false,
            },
          });
        }}
      >
        <ModalOverlay />
        <ModalContent maxH={"90vh"}>
          <ModalHeader color="white" bg="#2B2B2B">
            Password Changed
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setIsPopUpOpen(false);
              dispatch({
                type: "accountData/closePopUp",
                payload: {
                  modalOpen: false,
                },
              });
            }}
            color="white"
          />
          <ModalBody>
            <Box
              style={{
                fontWeight: "bold",
              }}
            >
              Success! Your Password has been updated successfully{" "}
              {`${
                userData?.transactionPassword &&
                "Transaction Password:" + userData?.transactionPassword
              }`}
            </Box>
            <Button
              onClick={() => {
                setIsPopUpOpen(false);
                dispatch({
                  type: "accountData/closePopUp",
                  payload: {
                    modalOpen: false,
                  },
                });
                dispatch({
                  type: "accountData/logOut",
                  payload: {},
                });
                window.localStorage.clear();
                window.location.href = "/";
              }}
            >
              Close{" "}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        bg="#0088CC"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        mb="5"
        color="white"
      >
        <Text>Change Passoword</Text>
      </Box>
      <Box w="40%">
        <Input
          type="password"
          placeholder="Transaction Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          mb="4"
        />
        <Input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb="4"
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          mb="4"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Box>
      <Button color="white" bg="#0088CC" onClick={changePassword}>
        Change Password
      </Button>
    </Box>
  );
}
